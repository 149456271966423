import { fetchUserAttributes, updateUserAttributes } from "@aws-amplify/auth";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Box, CardContent, IconButton, Link, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { styled } from "@mui/system";
import { fetchAuthSession } from "aws-amplify/auth";
import React, { useCallback, useEffect, useRef, useState } from "react";

const BorderlessCard = styled(Card)({
    border: "none",
    boxShadow: "none",
});

interface Paper {
    id: number;
    publisher: string;
    year: number;
    url: string;
    favicon: string;
    title: string;
    author: string[];
    abstract: string;
}

interface SearchResultBoxProps {
    queryResults: Paper[];
    selectedPublishers: string[];
    selectedYears: string[];
    query: string;
    keyword: string;
    isLoggedIn: boolean;
    fetchMoreData?: () => void;
    hasMoreData?: boolean;
}

interface AuthorTypographyProps {
    paper: Paper;
}

const AuthorTypography: React.FC<AuthorTypographyProps> = ({ paper }) => {
    const maxAuthors = 10;
    const isTruncated = paper.author.length > maxAuthors;
    const displayAuthors = isTruncated ? paper.author.slice(0, maxAuthors) : paper.author;
    const authorURL = "https://scholar.google.co.jp/citations?hl=en&view_op=search_authors&mauthors=";

    return (
        <Typography sx={{ mb: 0.5 }} color="text.secondary">
            {displayAuthors.map((author, index) => (
                <span key={index}>
                    <Link
                        href={`${authorURL}${encodeURIComponent(author.trim())}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                    >
                        {author}
                    </Link>
                    {index < displayAuthors.length - 1 && ", "}
                </span>
            ))}
            {isTruncated && "..."} ({paper.year})
        </Typography>
    );
};

const SearchResultBox: React.FC<SearchResultBoxProps> = ({
    queryResults,
    selectedPublishers,
    selectedYears,
    query,
    keyword,
    isLoggedIn,
    fetchMoreData,
    hasMoreData,
}) => {
    const [favorites, setFavorites] = useState<number[]>([]);
    const [bookmarks, setBookmarks] = useState<number[]>([]);
    const [checkedPapers, setCheckedPapers] = useState<number[]>([]);

    useEffect(() => {
        fetchUserPreferences();
    }, []);
    // すべてのユーザー設定を取得する関数
    const fetchUserPreferences = async () => {
        try {
            const attributes = await fetchUserAttributes();
            const favoritePapers = attributes["custom:favorite_papers"];
            const bookmarkedPapers = attributes["custom:bookmark_papers"];
            const checkedPapers = attributes["custom:checked_papers"];

            setFavorites(favoritePapers ? favoritePapers.split(",").map(Number) : []);
            setBookmarks(bookmarkedPapers ? bookmarkedPapers.split(",").map(Number) : []);
            setCheckedPapers(checkedPapers ? checkedPapers.split(",").map(Number) : []);
        } catch (error) {
            // console.error("Error fetching user preferences:", error);
        }
    };

    // 汎用的な状態更新関数
    const togglePaperState = async (
        paperID: number,
        currentState: number[],
        setState: React.Dispatch<React.SetStateAction<number[]>>,
        attributeName: string
    ) => {
        const isPaperIDIncluded = currentState.includes(paperID);
        const updatedState = currentState.includes(paperID)
            ? currentState.filter((id) => id !== paperID)
            : [...currentState, paperID];

        setState(updatedState);

        try {
            const userAttributes = {
                [attributeName]: updatedState.join(","),
            };
            await updateUserAttributes({ userAttributes });
        } catch (error) {
            console.error(`Error updating ${attributeName}:`, error);
            setState(currentState);
        }

        // dynamoDB
        try {
            const session = await fetchAuthSession();
            if (!session || !session.tokens || !session.tokens.accessToken) {
                throw new Error("Session tokens are undefined");
            }
            const accessToken = session.tokens.accessToken.toString();
            let action: string;
            if (attributeName == "custom:bookmark_papers") {
                action = "bookmarked";
            } else if (attributeName == "custom:favorite_papers") {
                action = "favorite";
            } else if (attributeName == "custom:checked_papers") {
                action = "checked";
            } else {
                return;
            }
            let http_method: string;
            // フラグを使用して処理を行う
            if (isPaperIDIncluded) {
                http_method = "DELETE";
            } else {
                http_method = "POST";
            }
            await fetch(`${process.env.REACT_APP_API_URL}/user_paper`, {
                method: http_method,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    origin: window.location.origin,
                },
                body: JSON.stringify({
                    action: action,
                    paper_id: paperID,
                }),
            });
        } catch (error) {}
    };

    const handlePaperClick =
        (paperID: number, url: string) => async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            window.open(url, "_blank", "noopener,noreferrer");
            try {
                const session = await fetchAuthSession();
                if (!session || !session.tokens || !session.tokens.accessToken) {
                    throw new Error("Session tokens are undefined");
                }
                const accessToken = session.tokens.accessToken.toString();
                await fetch(`${process.env.REACT_APP_API_URL}/user_paper`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        origin: window.location.origin,
                    },
                    body: JSON.stringify({
                        action: "accessed",
                        paper_id: paperID,
                    }),
                });
            } catch (error) {}
        };

    // 各アクションのトグル関数
    const toggleFavorite = (paperID: number) =>
        togglePaperState(paperID, favorites, setFavorites, "custom:favorite_papers");

    const toggleBookmark = (paperID: number) =>
        togglePaperState(paperID, bookmarks, setBookmarks, "custom:bookmark_papers");

    const toggleChecked = (paperID: number) =>
        togglePaperState(paperID, checkedPapers, setCheckedPapers, "custom:checked_papers");

    const [filteredResults, setFilteredResults] = useState<Paper[]>([]);

    useEffect(() => {
        const results = queryResults.filter(
            (paper) =>
                selectedPublishers.includes(paper.publisher) &&
                selectedYears.includes(String(Math.floor(paper.year / 10) * 10))
        );
        setFilteredResults(results);
    }, [queryResults, selectedPublishers, selectedYears]);

    // Intersection Observer の設定
    const observer = useRef<IntersectionObserver | null>(null);
    const lastResultRef = useCallback(
        (node: HTMLElement | null) => {
            if (fetchMoreData == null || !hasMoreData) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    fetchMoreData();
                }
            });

            if (node) observer.current.observe(node);
        },
        [fetchMoreData, hasMoreData, filteredResults]
    );

    return (
        <Box>
            <div>
                {queryResults.filter(
                    (paper) =>
                        selectedPublishers.includes(paper.publisher) &&
                        selectedYears.includes(String(Math.floor(paper.year / 10) * 10))
                ).length === 0 ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "50vh",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}
                        >
                            <Typography variant="h6" color="text.secondary" style={{ marginBottom: "30px" }}>
                                Your search - {query} and {keyword} - did not match any papers.
                            </Typography>
                        </div>
                    </div>
                ) : null}
            </div>
            {filteredResults.map((paper, index) => (
                <BorderlessCard
                    sx={{ minWidth: 275 }}
                    key={index}
                    ref={index === filteredResults.length - 1 ? lastResultRef : null} // 最後の要素にリファレンスを付与
                >
                    <CardContent>
                        <Link href={paper.url} underline="none" target="_blank" rel="noopener noreferrer">
                            <Typography
                                sx={{ fontSize: 14, display: "flex", alignItems: "center" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                <img src={paper.favicon} alt="favicon" style={{ height: "20px", marginRight: "5px" }} />
                                {paper.publisher}
                            </Typography>
                        </Link>
                        <Link href={paper.url} underline="hover" onClick={handlePaperClick(paper.id, paper.url)}>
                            <Typography variant="h5">{paper.title}</Typography>
                        </Link>
                        <AuthorTypography paper={paper} />
                        <Typography variant="body2">
                            {paper.abstract.length > 200 ? `${paper.abstract.substring(0, 250)}...` : paper.abstract}
                        </Typography>
                        {isLoggedIn && (
                            <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                <IconButton onClick={() => toggleFavorite(paper.id)}>
                                    {favorites.includes(paper.id) ? <StarIcon color="warning" /> : <StarBorderIcon />}
                                </IconButton>
                                <IconButton onClick={() => toggleBookmark(paper.id)}>
                                    {bookmarks.includes(paper.id) ? (
                                        <BookmarkIcon color="primary" />
                                    ) : (
                                        <BookmarkBorderIcon />
                                    )}
                                </IconButton>
                                <IconButton onClick={() => toggleChecked(paper.id)}>
                                    {checkedPapers.includes(paper.id) ? (
                                        <CheckCircleIcon color="success" />
                                    ) : (
                                        <CheckCircleOutlineIcon />
                                    )}
                                </IconButton>
                            </Box>
                        )}
                    </CardContent>
                </BorderlessCard>
            ))}
        </Box>
    );
};

export default SearchResultBox;
