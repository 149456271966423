import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Drawer, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// プロジェクト内のファイルからのインポート: バックエンド
import SearchResultPresenter from "../../backend/presenters/SearchResultPresenter";
import regularPaper from "../../dataclass/regularPaper";

// プロジェクト内のファイルからのインポート: コンポーネント
import AnalyzeResults from "../molecules/AnalyzeResults";
import DecadeSelector from "../molecules/DecadeSelector";
import KeywordSearchField from "../molecules/KeywordSearchField";
import LoadingScreen from "../molecules/LoadingScreen";
import NoResults from "../molecules/NoResults";
import PublisherSelection from "../molecules/PublisherSelection";
import SearchResultBox from "../molecules/SearchResultBox";
import SortSelector from "../molecules/SortSelector";

// プロジェクト内のファイルからのインポート: フック
import useDecadeFilter from "../hooks/search_filter/useDecadeFilter";
import useKeywordFilter from "../hooks/search_filter/useKeywordFilter";
import usePublisherFilter from "../hooks/search_filter/usePublisherFilter";
import useSortResults from "../hooks/search_filter/useSortResults";

import { fetchUserAttributes } from "@aws-amplify/auth";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface ResponsiveFilterDrawerProps {
    sortOrder: "desc" | "popularity" | "asc" | "default";
    setSortOrder: (order: "desc" | "popularity" | "asc" | "default") => void;
    keyword: string;
    setKeyword: (keyword: string) => void;
    uniquePublishers: string[];
    selectedPublishers: string[];
    handlePublisherChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sortedPublisherCounts: { [key: string]: number };
    uniqueDecades: string[];
    selectedYears: string[];
    handleYearChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sortedDecadeCounts: { [key: string]: number };
}

const ResponsiveFilterDrawer: React.FC<ResponsiveFilterDrawerProps> = ({
    sortOrder,
    setSortOrder,
    keyword,
    setKeyword,
    uniquePublishers,
    selectedPublishers,
    handlePublisherChange,
    sortedPublisherCounts,
    uniqueDecades,
    selectedYears,
    handleYearChange,
    sortedDecadeCounts,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const filterContent = (
        <Box sx={{ width: isMobile ? 250 : "100%" }}>
            {/* <Box sx={{ display: "flex", mb: 1 }}>
                <SortSelect sortOrder={sortOrder} setSortOrder={setSortOrder} />
            </Box> */}
            <Box sx={{ display: "flex", mb: 1 }}>
                <KeywordSearchField keyword={keyword} setKeyword={setKeyword} />
            </Box>
            <Box sx={{ display: "flex", mb: 1 }}>
                <PublisherSelection
                    uniquePublishers={uniquePublishers}
                    selectedPublishers={selectedPublishers}
                    handlePublisherChange={handlePublisherChange}
                    sortedPublisherCounts={sortedPublisherCounts}
                />
            </Box>
            <Box sx={{ display: "flex", mb: 2 }}>
                <DecadeSelector
                    uniqueDecades={uniqueDecades}
                    selectedYears={selectedYears}
                    handleYearChange={handleYearChange}
                    sortedDecadeCounts={sortedDecadeCounts}
                />
            </Box>
        </Box>
    );

    if (isMobile) {
        return (
            <>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    sx={{ ml: 0.1 }}
                >
                    <FilterListIcon />
                </IconButton>
                <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                    {filterContent}
                </Drawer>
            </>
        );
    }

    return filterContent;
};

const SearchResultsBox: React.FC = () => {
    const query = useQuery().get("query") || "";
    const order = useQuery().get("order") || "";
    let tbm = useQuery().get("tbm");
    // 状態変数を追加
    const [loading, setLoading] = useState(false);
    const [fetchInProgress, setFetchInProgress] = useState(false);

    const [queryResults, setQueryResults] = useState<regularPaper[]>([]);
    // フィルタリング前のデータを保持するためのstateを作成
    const [originalResults, setOriginalResults] = useState<regularPaper[]>([]);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);

    const fetchData = async (limit: number | null, offset: number | null, isMounted: boolean) => {
        const results = await SearchResultPresenter(query, limit, offset, order);
        if (offset === null) {
            // 初回取得時はデータをセット
            if (isMounted) {
                setQueryResults(results);
                setOriginalResults(results);
                setLoading(false);
            }
        } else {
            if (isMounted) {
                if (results.length === 0) {
                    setHasMoreData(false);
                }
                setQueryResults((prevResults) => [...prevResults, ...results]);
                setOriginalResults((prevResults) => [...prevResults, ...results]);
            }
        }
    };

    const paperLimit = 50;

    useEffect(() => {
        let isMounted = true; // マウントされているかどうかのフラグ
        if (query !== null && query !== undefined) {
            setLoading(true); // 読み込み開始

            const authenticateAndGetSubId = async () => {
                try {
                    const currentUserAttributes = await fetchUserAttributes();
                    if (isMounted) {
                        setIsLoggedIn(true);
                    }
                } catch (error) {
                    if (isMounted) {
                        setIsLoggedIn(false);
                    }
                }
            };
            authenticateAndGetSubId();

            const fetchInSteps = async () => {
                setFetchInProgress(true);
                await fetchData(paperLimit, null, isMounted);
                setFetchInProgress(false);
            };

            fetchInSteps();

            return () => {
                isMounted = false; // クリーンアップでフラグを更新
                setLoading(false); // ローディング状態をクリア
            };
        }
    }, [query]);

    // 追加データを取得する関数
    const fetchMoreData = async () => {
        if (!fetchInProgress) {
            setFetchInProgress(true);
            const newOffset = offset + paperLimit;
            await fetchData(paperLimit, newOffset, true);
            setOffset(newOffset);
            setFetchInProgress(false);
        }
    };

    const [offset, setOffset] = useState(0);

    /***********
     *並び替え機能(年代)
     * ここから変更する
     **********/
    const { sortOrder, setSortOrder, sortResults } = useSortResults(originalResults);
    // useEffect内でsortResultsを呼び出す
    useEffect(() => {
        const sortedResults = sortResults(sortOrder);
        setQueryResults(sortedResults);
        // eslint-disable-next-line
    }, [sortOrder]);

    /***********
     *キーワード絞り込み機能
     **********/
    const { keyword, setKeyword, filterResults } = useKeywordFilter(originalResults);

    // useEffectを使用して、キーワードが変更されたときにフィルタリングを行うようにする
    useEffect(() => {
        if (keyword) {
            const filteredResults = filterResults(keyword);
            setQueryResults(filteredResults);
        }
        // eslint-disable-next-line
    }, [keyword]);

    /***********
     *フィルター機能(出版社)
     **********/
    const { uniquePublishers, selectedPublishers, handlePublisherChange, sortedPublisherCounts } =
        usePublisherFilter(queryResults);

    /***********
     *フィルター機能(年代)
     **********/
    const { uniqueDecades, selectedYears, handleYearChange, sortedDecadeCounts } = useDecadeFilter(queryResults);

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : originalResults.length === 0 ? (
                <NoResults query={query} />
            ) : tbm === "ana" ? (
                <div className="bg-gray-100 flex justify-center">
                    <div className="w-full sm:w-11/12">
                        <AnalyzeResults
                            query={query}
                            queryResults={queryResults}
                            sortedPublisherCounts={sortedPublisherCounts}
                        />
                    </div>
                </div>
            ) : (
                <div className="flex justify-center">
                    <div className="w-full sm:w-11/12">
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={2.3}>
                                <SortSelector />
                                <ResponsiveFilterDrawer
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    keyword={keyword}
                                    setKeyword={setKeyword}
                                    uniquePublishers={uniquePublishers}
                                    selectedPublishers={selectedPublishers}
                                    handlePublisherChange={handlePublisherChange}
                                    sortedPublisherCounts={sortedPublisherCounts}
                                    uniqueDecades={uniqueDecades}
                                    selectedYears={selectedYears}
                                    handleYearChange={handleYearChange}
                                    sortedDecadeCounts={sortedDecadeCounts}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <SearchResultBox
                                    queryResults={queryResults}
                                    selectedPublishers={selectedPublishers}
                                    selectedYears={selectedYears}
                                    query={query}
                                    keyword={keyword}
                                    isLoggedIn={isLoggedIn}
                                    fetchMoreData={fetchMoreData} // 追加データ取得関数を渡す
                                    hasMoreData={hasMoreData}
                                />
                                {fetchInProgress && (
                                    <div className="flex items-center justify-center my-4">
                                        <CircularProgress size={20} className="mr-2" />
                                        <Typography variant="body1">Loading more data...</Typography>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={2.7} className="hidden sm:block"></Grid>
                        </Grid>
                    </div>
                </div>
            )}
        </>
    );
};

export default SearchResultsBox;
