import React from "react";
import Footer from "../organisms/Footer";
import HeaderBar from "../organisms/HeaderBar";
import MyDashboardBox from "../organisms/MyDashboardBox";
import { MainPageProps } from "../organisms/types";

const MyPageTemplate: React.FC<MainPageProps> = ({ signOut, user }) => {
    return (
        <div className="flex flex-col min-h-screen">
            <HeaderBar />
            <main className="flex-grow container mx-auto">
                <div className="flex flex-col lg:flex-row">
                    <div className="lg:w-2/3 w-full mx-auto">
                        <MyDashboardBox user={user} signOut={signOut} />
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default MyPageTemplate;
